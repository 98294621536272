import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-session-card',
  templateUrl: './session-card.component.html',
  styleUrls: ['./session-card.component.scss']
})
export class SessionCardComponent implements OnInit {

  @Input()
  get sessionName(): string {
    return this._sessionName;
  }
  set sessionName(sessionName: string) {
    this._sessionName = sessionName === undefined ? "Session Name" : sessionName;
  }

  private _sessionName = "Session Name";

  @Input()
  get productIcon(): string {
    return this._productIcon;
  }
  set productIcon(productIcon: string) {
    this._productIcon = productIcon === undefined ? "../../../../../assets/images/icons/Iconawesome-image.svg" : productIcon;
  }

  private _productIcon = "../../../../../assets/images/icons/Iconawesome-image.svg";

  constructor() { }

  ngOnInit(): void {
  }

}
