import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  @Input()
  get adminProfileImg(): string {
    return this._adminProfileImg;
  }
  set adminProfileImg(adminProfileImg: string) {
    this._adminProfileImg = adminProfileImg === undefined ? "../../../../../assets/images/icons/Iconawesome-image.svg" : adminProfileImg;
  }

  private _adminProfileImg = "../../../../../assets/images/icons/Iconawesome-image.svg";

  @Input()
  get adminName(): string {
    return this._adminName;
  }
  set adminName(adminName: string) {
    this._adminName = adminName === undefined ? "John Doe" : adminName;
  }

  private _adminName = "John Doe";

  @Input()
  get adminAction(): string {
    return this._adminAction;
  }
  set adminAction(adminAction: string) {
    this._adminAction = adminAction === undefined ? "Add Product name To cart" : adminAction;
  }

  private _adminAction = "Add Product name To cart";

  @Input()
  get adminProduct(): string {
    return this._adminProduct;
  }
  set adminProduct(adminProduct: string) {
    this._adminProduct = adminProduct === undefined ? "../../../../../assets/images/icons/Iconawesome-image.svg" : adminProduct;
  }

  private _adminProduct = "../../../../../assets/images/icons/Iconawesome-image.svg";

  constructor() { }

  ngOnInit(): void {
  }

}
