import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  dataModel=''
  title = 'admin-fe';
   config = {
    displayKey:"name", 
    search:true, 
    height: 'auto',
    placeholder:'Select',
    customComparator: ()=>{} ,
    limitTo: 0,
    moreText: 'more' ,
    noResultsFound: 'No results found!' ,
    searchPlaceholder:'Search' ,
    searchOnKey: 'name'
    }
    dropdownOptions= [
      { name:"shuoukry"}
    ]
}
