import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../../@resources/services/local-storage/local-storage.service';
import axios from 'axios';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent implements OnInit {
  orders: any;
  p: number = 1;
  searchKey: string;
  searchVariable: string;
  type: any = 'orederCode';

  constructor(
    private storage: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.orders = await this.listBoardOrders();
    // if(this.orders[0].order_status == 'PENDING'){
    //   document.getElementById('status').style.color = 'red';
    // }
    // console.log('>>>', this.orders[0].order_status)
  }

  async searchByKey(searchKey) {
    this.searchVariable = searchKey.target.value;
    this.orders = await this.listBoardOrders(searchKey.target.value);
  }

  async listBoardOrders(searchKey?: string) {
    const authorization = this.storage.get('AUTHORIZATION');
    if (authorization === null) return;
    const res = await axios({
      url: 'https://board-api.amandamed.com/graphql',
      method: 'post',
      headers: {
        Authorization: `Bearer ${authorization}`,
        'Content-type': 'application/json',
      },
      data: {
        query: `
        query Orders{
          orders(filter:{sort:DESC}){
            orderId
            orderCode
            customer{
              is_saudi_citizen
              avatar
              firstName
              lastName
            }
            payment{
              payment_type
              payment_option
            }
            tax
            price
            totalPrice
            order_status
            createdAt
          }
        }
        `,
      },
    });
    return res.data.data.orders.filter((order) => {
      if (searchKey && searchKey == order.orderCode) {
        return order;
      } else if (!searchKey) {
        return order;
      }
    });
  }
}
