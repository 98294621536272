import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../@resources/services/local-storage/local-storage.service';
import axios from 'axios';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-shipping-table',
  templateUrl: './shipping-table.component.html',
  styleUrls: ['./shipping-table.component.scss'],
})
export class ShippingTableComponent implements OnInit {
  order: any;
  orderId: string;
  p: number = 1;

  constructor(
    public dialog: MatDialog,
    private storage: LocalStorageService,
    private route: ActivatedRoute
  ) {}
  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (value) => {
      this.orderId = value.orderId;
    });
    this.order = await this.listBoardOrdersDetails();
  }
  async listBoardOrdersDetails() {
    const authorization = this.storage.get('AUTHORIZATION');
    if (authorization === null) return;
    const res = await axios({
      url: 'https://board-api.amandamed.com/graphql',
      method: 'post',
      headers: {
        Authorization: `Bearer ${authorization}`,
        'Content-type': 'application/json',
      },
      data: {
        query: `
        query Order($orderId: String!){
          order(orderId: $orderId){
            orderId
            orderCode
            items{
              english_name
              arabic_name
              lst_price
              qty
            }
            tax
            totalPrice
          }
        }
        `,
        variables: { orderId: this.orderId },
      },
    });
    return res.data.data.order;
  }
}
