import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgModel, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { CardStatsComponent } from './components/cards/card-stats/card-stats.component';
import { LatestOrderCardComponent } from './components/cards/latest-order-card/latest-order-card.component';
import { NotificationCardComponent } from './components/cards/notification-card/notification-card.component';
import { ProductCardComponent } from './components/cards/product-card/product-card.component';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { OrdersTableComponent } from './components/tables/orders-table/orders-table.component';
import { ShippingTableComponent } from './components/tables/shipping-table/shipping-table.component';
import { RouterModule } from '@angular/router';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OfferTableComponent } from './components/tables/offer-table/offer-table.component';
import { OfferFormComponent } from './components/tables/offer-form/offer-form.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { SessionCardComponent } from './components/cards/session-card/session-card.component';
import { LatestAppointmentCardComponent } from './components/cards/latest-appointment-card/latest-appointment-card.component';
import { SessionsTableComponent } from './components/tables/sessions-table/sessions-table.component';

import { QuillModule } from 'ngx-quill';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatChipsModule } from '@angular/material/chips';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    CardStatsComponent,
    LatestOrderCardComponent,
    NotificationCardComponent,
    ProductCardComponent,
    OrdersTableComponent,
    ShippingTableComponent,
    OfferTableComponent,
    OfferFormComponent,
    SessionCardComponent,
    LatestAppointmentCardComponent,
    SessionsTableComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule,
    InfiniteScrollModule,
    MatDialogModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    NgxPaginationModule
  ],
  exports: [
    AngularSvgIconModule,
    FroalaEditorModule,
    FroalaViewModule,
    CardStatsComponent,
    LatestOrderCardComponent,
    NotificationCardComponent,
    ProductCardComponent,
    OrdersTableComponent,
    ShippingTableComponent,
    OfferTableComponent,
    SessionCardComponent,
    LatestAppointmentCardComponent,
    SessionsTableComponent,
    QuillModule,
    ToastrModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
  ],
})
export class SharedModule {}
