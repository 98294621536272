import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {MatDialog} from '@angular/material/dialog';
import { OfferFormComponent } from '../offer-form/offer-form.component';

@Component({
  selector: 'app-offer-table',
  templateUrl: './offer-table.component.html',
  styleUrls: ['./offer-table.component.scss']
})
export class OfferTableComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(OfferFormComponent);
  }

  DeleteFn() {
    Swal.fire({
      title: '<div class="text-primary1 text-lg">Do you want to delete the product?</div>',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonColor: '#a90520',
      confirmButtonText: `Delete`,
      cancelButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('<div class="size-12 weight-700 text-primary">The product has been deleted</div>','',
         'success')
      }
    })
  }

}
