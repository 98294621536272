<a class="flex items-center justify-between bg-gray-50 py-3 px-7 text-primary1 w-full rounded mb-5" [routerLink]="['/clients/client-details']">
  <div class="flex items-center">
    <svg-icon class="bg-white mr-5 w-20 h-20 flex justify-center items-center rounded-full" src="../../../../../../assets/images/icons/../../../../../assets/images/icons/Iconawesome-image.svg"></svg-icon>
    <div>
      <div class="text-xl font-semibold"> Joe Doe</div>
      <div class="flex items-center mt-1">
        <div class="font-thin mr-4">Session Name</div>
        <div class="flex items-center">
          <svg-icon class="text-8px" src="../../../../../../assets/images/icons/dot.svg" ></svg-icon>
          <div class="ml-1 font-thin">In Progress</div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center">
    <div class="flex items-center ml-4 opacity-40">
      <svg-icon class="text-8px mr-1 font-thin" src="../../../../../../assets/images/icons/dot.svg" ></svg-icon>
      <div class="font-thin">4 days ago</div>
    </div>
  </div>
</a>
