import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../@resources/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
	constructor(private router: Router, private storage: LocalStorageService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.checkIfCanActivate(state);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.checkIfCanActivate(state);
	}

	checkIfCanActivate(state) {
		if (this.storage.get('ADMIN_TOKEN')) {
			return true;
		}
		this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
		return false;
	}

}
