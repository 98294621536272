<!-- This example requires Tailwind CSS v2.0+ -->
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border-t border-b border-gray-111 rounded">
              <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                      <tr>
                          <th scope="col"
                              class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Product
                          </th>
                          <th scope="col"
                              class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Quantity
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Price
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Total
                          </th>
                      </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-111">
                      <tr class="border-t border-b border-gray-111" *ngFor="let item of order?.items">
                          <td class="px-6 py-4 whitespace-wrap">
                            <div class="text-md text-green-556 max-w-xs">{{item?.arabic_name}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">{{item?.qty}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {{item?.lst_price}}
                              </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{item?.lst_price * item?.qty}}
                          </td>
                      </tr>
                      <tr class="border-gray-111">
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            Tax:
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{order?.tax}}
                        </td>
                    </tr>
                    <tr class="border-gray-111 bg-green-100">
                        <td class="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                            Total price:
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                            {{order?.totalPrice}}
                        </td>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
