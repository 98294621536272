<div class="w-800-px">
  <div class="bg-green-556 py-2 px-5 text-white flex items-center font-bold justify-between rounded-t border border-green-556">
    <div>Session</div>
    <button mat-button mat-dialog-close class="focus:outline-none"><svg-icon src="../../../../../assets/images/icons/cancel.svg"></svg-icon></button>
  </div>
  <div class="bg-white px-5 py-8">
    <form action="" class="w-full">

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="session-name">
            Session Name - English
          </label>
          <div class="flex mb-3">
            <svg-icon class="flex items-center justify-center border rounded-l border-r-0 pl-3 text-gray-557" src="../../../../../assets/images/icons/aaclock.svg"></svg-icon>
            <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded rounded-l-none border-l-0 py-2 px-4 leading-tight focus:outline-none focus:bg-white" id="session-name" type="text" placeholder="Session Name">
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="session-name">
            Session Name - Arabic
          </label>
          <div class="flex mb-3">
            <svg-icon class="flex items-center justify-center border rounded-l border-r-0 pl-3 text-gray-557" src="../../../../../assets/images/icons/aaclock.svg"></svg-icon>
            <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded rounded-l-none border-l-0 py-2 px-4 leading-tight focus:outline-none focus:bg-white" id="session-name" type="text" placeholder="Session Name">
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="price">
            Price
          </label>
          <div class="flex mb-3">
            <svg-icon class="flex items-center justify-center border rounded-l border-r-0 pl-3 text-gray-557" src="../../../../../assets/images/icons/dollar.svg"></svg-icon>
            <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded rounded-l-none border-l-0 py-2 px-4 leading-tight focus:outline-none focus:bg-white" id="price" type="text" placeholder="Price">
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="sale-price">
            Sale Price
          </label>
          <div class="flex mb-3">
            <svg-icon class="flex items-center justify-center border rounded-l border-r-0 pl-3 text-gray-557" src="../../../../../assets/images/icons/dollar.svg"></svg-icon>
            <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded rounded-l-none border-l-0 py-2 px-4 leading-tight focus:outline-none focus:bg-white" id="sale-price" type="text" placeholder="Sale Price">
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="img">
            Session Image
          </label>
          <div class="flex mb-3">
            <input type="file" id="img" name="img" accept="image/*">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="flex justify-between w-full py-5 px-5 bg-gray-111 rounded-b border border-transparent">
    <button mat-button mat-dialog-close class="bg-gray-557 hover:bg-dark-brown transition-colors text-white flex items-center focus:outline-none">Close <svg-icon class="size-10px" src="../../../../../assets/images/icons/cancel.svg"></svg-icon></button>
    <button mat-button [mat-dialog-close]="true" class="bg-green-556 hover:bg-dark-green transition-colors text-white focus:outline-none" cdkFocusInitial>Save</button>
  </div>
</div>
