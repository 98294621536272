<div class="flex items-center justify-between bg-gray-200 py-3 px-7 text-primary1 rounded-xl mb-5">
  <div class="flex items-center">
    <svg-icon class="bg-gray-50 mr-5 w-12 h-12 flex justify-center items-center rounded-full" [src]="adminProfileImg"></svg-icon>
    <div>
      <div class="text-xl font-semibold"> {{ adminName }}</div>
      <div class="text-sm text-black opacity-70"> {{ adminAction }} </div>
    </div>
  </div>
  <svg-icon class="bg-gray-50 w-12 h-12 flex justify-center items-center rounded-xl" [src]="adminProduct"></svg-icon>
</div>
