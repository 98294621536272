<div class="toolbar flex items-center justify-between bg-gray-112 border border-b-0 rounded-t p-5">
  <div class="flex items-center justify-between">
        <div class="text-lg">Orders</div>
  </div>
  <div class="flex items-center">
    <form class="mr-2 relative" action="">
      <span class="absolute left-2">
        <svg-icon class="text-sm" src='../../../../assets/images/icons/Iconawesome-search.svg'></svg-icon>
      </span>
      <input class="md:w-72 border rounded-tl rounded-bl pl-8 py-1 text-sm" type="search" placeholder="Search by order code" [(ngModel)]="searchKey" (input)="searchByKey($event)"
       autocomplete="off"/>
    </form>
  </div>
</div>
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border rounded-b">
              <div class="">
                  <table class="min-w-full divide-y divide-gray-200">
                      <tbody class="bg-white divide-y divide-gray-200">
                          <tr>
                            <th class="p-5 whitespace-nowrap text-left text-sm font-medium">Client</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Order code</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Payment method</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Order status</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Tax</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Price</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Total Price</th>
                            <th class="p-5 whitespace-nowrap text-right text-sm font-medium">Date</th>
                          </tr>
                          <tr *ngFor="let order of orders | paginate: { itemsPerPage: 10, currentPage: p }" :key="order.orderId">
                              <td class="p-5 whitespace-nowrap">
                                <a [routerLink]="['/pages/orders/order-details/' + order.orderId]">
                                  <div class="flex items-center">
                                        <div class="flex-shrink-0 h-10 w-10"  >
                                            <img class="h-10 w-10 rounded-full"
                                                src="{{order?.customer?.avatar}}"
                                                alt="Avatar">
                                        </div>
                                        <div class="ml-4 text-sm flex items-center font-medium text-primary1">
                                            <div>{{order?.customer?.firstName}} {{order?.customer?.lastName}}</div>
                                        </div>
                                  </div>
                                </a>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                <div>{{order?.orderCode}}</div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                <div>{{order?.payment.payment_option}}</div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                  <div id="status">{{order?.order_status}}</div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                <div>{{order?.tax}} 
                                  <span *ngIf="order.customer.is_saudi_citizen; else is_saudi">(0%)</span>  
                                  <ng-template #is_saudi>(15%)</ng-template>
                                </div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                <div>{{order?.price}}</div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium">
                                <div>{{order?.totalPrice}}</div>
                              </td>
                              <td class="p-5 whitespace-nowrap text-right text-sm font-medium w-44">
                                  <div>{{order?.createdAt | date : 'd/M/yyyy'}}</div>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <pagination-controls (pageChange)="p = $event" class="flex justify-center pt-5"></pagination-controls>
        </div>
  </div>
