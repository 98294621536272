<div class="flex items-center justify-between bg-gray-50 py-3 px-7 text-primary1 w-full rounded mb-5">
  <div class="flex items-center">
    <svg-icon class="bg-white mr-5 w-20 h-20 flex justify-center items-center rounded-full" src="../../../../../../assets/images/icons/../../../../../assets/images/icons/Iconawesome-image.svg"></svg-icon>
    <div>
      <div class="text-xl font-semibold"> Joe Doe</div>
      <div class="flex items-center mt-1">
        <div class="font-thin">#437615</div>
        <div class="flex items-center md:mx-4">
          <svg-icon class="text-xs" src="../../../../../../assets/images/icons/../../../../../assets/images/icons/Iconmaterial-location-on.svg"></svg-icon>
          <div class="ml-1 font-thin">Cairo , Egypt</div>
        </div>
        <div class="flex items-center">
          <svg-icon class="text-xs" src="../../../../../../assets/images/icons/dot.svg" ></svg-icon>
          <div class="ml-1 font-thin">Deleted</div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center">
    <div>4000 L.E</div>
    <div class="flex items-center ml-4 opacity-40">
      <svg-icon class="text-xs mr-1 font-thin" src="../../../../../../assets/images/icons/dot.svg" ></svg-icon>
      <div class="font-thin">4 days ago</div>
    </div>
  </div>
</div>
