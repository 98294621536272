import { LocalStorageService } from './../@resources/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
// import { LocalStorageService } from './../@resources/services/local-storage.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

const uri = environment.url;

export function createApollo(
  httpLink: HttpLink,
  router:Router,
  localStorage:LocalStorageService,
  toastrService: ToastrService,
  ) {
	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.map(({ extensions, locations, path }) => {
				if (graphQLErrors) {
					if (graphQLErrors[0].extensions.exception.response.message) {

						if (Array.isArray(graphQLErrors[0].extensions.exception.response.message)) {
							const errArr = [...graphQLErrors[0].extensions.exception.response.message];
							errArr.forEach(element => {
							});
						} else {
							if(graphQLErrors[0].extensions.exception.response.message == "Invalid token"){
								localStorage.clear();
								router.navigate(['./auth/login'])
							}
						}
					} else {
					}
				}
			});
	});

	const defaultOptions: DefaultOptions = {
		watchQuery: {
		  fetchPolicy: 'no-cache',
		  errorPolicy: 'ignore',
		},
		query: {
		  fetchPolicy: 'no-cache',
		  errorPolicy: 'all',
		},
	  }
	const basic = setContext((operation, context) => ({
		headers: {
			Accept: 'charset=utf-8'
		}
	}));

	const auth = setContext((operation, context) => {
		const token = localStorage.get('ADMIN_TOKEN');
		if (token === null) return {}; else return { headers: { Authorization: `Bearer ${token}` } };
	});

	const link = ApolloLink.from([basic, auth, (errorLink as unknown) as ApolloLink, httpLink.create({ uri })]);
	const cache = new InMemoryCache();
	return {
		link,
		cache,
		defaultOptions: defaultOptions,
	}
}

@NgModule({
	exports: [
		HttpClientModule,
	],
	providers: [{
		provide: APOLLO_OPTIONS,
		useFactory: createApollo,
		deps: [HttpLink,Router,LocalStorageService]
	}]
})
export class GraphQLModule { }
