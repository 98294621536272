import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input()
  get productName(): string {
    return this._productName;
  }
  set productName(productName: string) {
    this._productName = productName === undefined ? "Product Name" : productName;
  }

  private _productName = "Product Name";

  @Input()
  get productDetails(): string {
    return this._productDetails;
  }
  set productDetails(productDetails: string) {
    this._productDetails = productDetails === undefined ? "Product Details" : productDetails;
  }

  private _productDetails = "Product Details";

  @Input()
  get productIcon(): string {
    return this._productIcon;
  }
  set productIcon(productIcon: string) {
    this._productIcon = productIcon === undefined ? "../../../../../assets/images/icons/Iconawesome-image.svg" : productIcon;
  }

  private _productIcon = "../../../../../assets/images/icons/Iconawesome-image.svg";

  constructor() { }

  ngOnInit(): void {
  }

}
